/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Libraries
import Select from 'react-select'
import scrollTo from 'gatsby-plugin-smoothscroll'

// Components
import ButtonOrangeBig from 'components/elements/ButtonOrangeBig'
import Content from 'components/shared/Content'
import Loading from 'components/shared/Loading'

const StyledForm = styled.form`
  position: relative;
`

const ConfirmationMessage = styled(Content)`
  font-size: ${(props) => props.theme.font.size.l};
  text-align: center;
`

const Label = styled.label`
  font-size: ${(props) => props.theme.font.size.m};
  font-weight: ${(props) => props.theme.font.weight.s};
  display: block;
  padding: 0 20px;
`

const Mandatory = styled.span`
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.color.face.contrast};
  margin-left: 5px;
`

const InputCSS = css`
  border: 1px solid ${(props) => props.theme.color.face.border};
  height: 40px;
  border-radius: 20px;
  width: 100%;
  padding: 15px 20px;

  &::placeholder {
    color: ${(props) => props.theme.color.text.grey};
    font-style: italic;
  }
`

interface GravityFormDefaultValuesProps {
  [key: string]: string
}

interface GravityFormProps {
  id: number
  className?: string
  defaultValues?: GravityFormDefaultValuesProps
}

const GravityForm = ({
  className = '',
  id,
  defaultValues = {},
}: GravityFormProps) => {
  const {
    allGfForm: { edges: gravityData },
  } = useStaticQuery<GatsbyTypes.GravityFormsQueryQuery>(graphql`
    query GravityFormsQuery {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(
    ({ node }: any) => node.formId === id
  )[0]

  const [fields, setFields] = useState<any>({})
  const [form, setForm] = useState<any>(gfForm)
  const [status, setStatus] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [privacy, setPrivacy] = useState<boolean>(false)
  const [privacyMessage, setPrivacyMessage] = useState<string>('')
  const uid: string = `form_${gfForm.formId}`

  console.log(privacyMessage)

  useEffect(() => {
    setFields(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    const tempForm = form

    // add submit button as a field
    if (
      tempForm.formFields.filter((item: any) => item.type === 'submit')
        .length === 0
    ) {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: id,
          type: 'submit',
          text: tempForm.button.text,
        },
      ]
    }

    setForm({
      ...form,
      ...tempForm,
    })
  }, [])

  useEffect(() => {
    if (status === 'done') {
      scrollTo(`#${uid}-ref`, 'center')
    }
  }, [status])

  async function handleOnSubmit(event: any) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }

    // check if privacy is true
    if (!privacy) {
      setStatus('error')
      setPrivacyMessage('Je moet de privacyverklaring accepteren')
      return
    }

    setStatus('processing')

    try {
      const formData = new FormData()

      Object.keys(defaultValues).forEach((key: string) => {
        formData.append(key, defaultValues[key])
      })

      Object.keys(fields).forEach((key: string) => {
        formData.append(key, fields[key])
      })

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData,
      })

      const response = await request.json()

      if (response.is_valid === true) {
        // if form id = 15, then redirect to the thank you page
        if (form.formId === 15) {
          window.location.href = '/bedankpagina-brancherapport/'
          return
        }
        setMessage(response.confirmation_message)
        setStatus('done')
      } else {
        setStatus('error')
      }
    } catch (error) {
      setStatus('error')
    }
  }

  function handleFieldChange(event: any) {
    let { value } = event.target || event.value

    if (event.target) {
      if (event.target.type === 'checkbox') {
        value = event.target.checked ? event.target.value : ''
      }

      setFields({
        ...fields,
        [event.target.name]: value,
      })
    } else {
      setFields({
        ...fields,
        [event.name]: event.value,
      })
    }
  }

  return (
    <div id={`${uid}-ref`}>
      {status === 'done' && (
        <ConfirmationMessage className="py-5 my-5" content={message} />
      )}
      {form.formFields && status !== 'done' && (
        <StyledForm
          id={uid}
          className={className}
          method="post"
          onSubmit={handleOnSubmit}
        >
          {status === 'processing' && <Loading />}
          {form.formFields &&
            form.formFields.map((field: any, key: number) => {
              if (field.visibility === 'hidden') {
                return null
              }

              if (Array.isArray(field)) {
                return (
                  <div key={key}>
                    {field.map((item, index) => (
                      <FormField
                        key={index}
                        field={item}
                        fields={fields}
                        onChange={handleFieldChange}
                        setPrivacy={setPrivacy}
                        privacy={setPrivacy}
                        privacyMessage={privacyMessage}
                      />
                    ))}
                  </div>
                )
              }

              return (
                <div key={key}>
                  <FormField
                    field={field}
                    fields={fields}
                    onChange={handleFieldChange}
                    setPrivacy={setPrivacy}
                    privacy={privacy}
                    privacyMessage={privacyMessage}
                  />
                </div>
              )
            })}
        </StyledForm>
      )}
    </div>
  )
}

const InputTextField = styled.input`
  ${InputCSS};
`

const TextField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}: any) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <InputTextField
      value={value || ''}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const InputEmailField = styled.input`
  ${InputCSS};
`

const EmailField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}: any) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <InputEmailField
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const InputPhoneField = styled.input`
  ${InputCSS};
`

const PhoneField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}: any) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <InputPhoneField
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const InputTextArea = styled.textarea`
  ${InputCSS};
  height: 150px;
`

const TextAreaField = ({
  value,
  onChange,
  field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass },
}: any) => (
  <div className="form-group">
    {labelPlacement !== 'hidden_label' && (
      <Label htmlFor={`input_${id}`}>
        {label}
        {isRequired && <Mandatory>*</Mandatory>}
      </Label>
    )}
    <InputTextArea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const CheckboxField = ({
  value,
  onChange,
  field: { id, type, cssClass, choices },
}: any) => {
  const list = JSON.parse(choices)

  return (
    <div className="form-group">
      {list.map((checkbox: any, key: number) => (
        <div
          key={key}
          className="form-group__checkboxes d-flex justify-content-between"
        >
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ''} mt-1`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label
            htmlFor={`input_${id}_${key + 1}`}
            className="checkbox-content ml-2"
          >
            {checkbox.text}
          </label>
        </div>
      ))}
    </div>
  )
}

const StyledSelect = styled.div``

// Select or Dropdown
const SelectField = ({ field, onChange, value }: any) => {
  // Populate a options array
  const options: Array<any> = []
  JSON.parse(field.choices).forEach((choice: any) => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`,
    })
  })

  // Custom Select component
  const MySelect = (props: any) => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <StyledSelect>
      <MySelect
        options={options}
        onChange={onChange}
        value={options.filter((option) => option.value === value)}
        placeholder={field.label}
        classNamePrefix="react-select"
      />
    </StyledSelect>
  )
}

const PrivacyDescription = styled.button`
  font-size: ${(props) => props.theme.font.size.sm};
  color: ${(props) => props.theme.color.text.purple};
  text-align: left;

  & a {
    text-decoration: underline;
    color: ${(props) => props.theme.color.text.purple};

    &:hover {
      color: ${(props) => props.theme.color.text.purple};
    }
  }

  & .button a {
    text-decoration: none;
  }
`

const PrivacyDescriptionBox = styled.span`
  border: 2px solid ${(props) => props.theme.color.face.border};
  background-color: ${({ theme }) => theme.color.face.background};
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 15px;
    height: 15px;

    & path {
      fill: #0098a5;
    }
  }
`

const SubmitButton = ({ field, privacy, setPrivacy, privacyMessage }: any) => (
  <>
    <div className="d-lg-flex justify-content-between align-items-center mt-3 mt-lg-5">
      <PrivacyDescription
        type="button"
        onClick={() => {
          setPrivacy((prevState: boolean) => !prevState)
        }}
        className="px-0 d-flex align-items-center"
      >
        <PrivacyDescriptionBox className="mr-3">
          {privacy && (
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 174.239 174.239"
            >
              <path
                d="M74.439,157.519c-1.59,1.848-4.34,1.989-6.111,0.313L1.38,94.468c-1.771-1.676-1.848-4.496-0.173-6.267l21.33-22.539
	c1.676-1.771,4.496-1.848,6.267-0.173l36.571,34.611c1.771,1.676,4.521,1.535,6.111-0.313l71.447-83.015
	c1.59-1.848,4.404-2.059,6.251-0.468l23.518,20.242c1.848,1.59,2.059,4.404,0.468,6.252L74.439,157.519z"
              />
            </svg>
          )}
        </PrivacyDescriptionBox>
        <span className="pr-1">
          Ik accepteer de&nbsp;
          <a href="/privacyverklaring" target="_blank">
            privacyverklaring
          </a>
        </span>
      </PrivacyDescription>
      <ButtonOrangeBig className="mt-4 mt-lg-0" to="/" isCustom>
        <button type="submit">{field.text}</button>
      </ButtonOrangeBig>
    </div>
    <div className="mt-3">
      {privacyMessage !== '' && <p>{privacyMessage}</p>}
    </div>
  </>
)

const HtmlField = styled.div`
  & p {
    font-size: 14px;
    text-align: center;
  }
`

const HTMLField = ({ field: { content } }: any) => (
  <HtmlField>
    <p>{content}</p>
  </HtmlField>
)

const FileField = ({
  value,
  onChange,
  field: { id, type, label, allowedExtensions, cssClass, placeholder },
}: any) => {
  function handleChange(file: any) {
    onChange({
      target: {
        name: `input_${id}`,
        value: file,
      },
    })
  }

  function handleFileUpload(e: any) {
    const {
      e: {
        target: { files },
      },
    } = e

    for (let i = 0; i < files.length; i += 1) {
      handleChange(files[i])
    }
  }

  function handleFileDrop(e: any) {
    e.preventDefault()

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          handleChange(e.dataTransfer.items[i].getAsFile())
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        handleChange(e.dataTransfer.files[i])
      }
    }
  }

  return (
    <div
      className="form-upload"
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <Label htmlFor={`input_${id}`}>{value ? value.name : <>{label}</>}</Label>
      <input
        type="file"
        onChange={handleFileUpload}
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ''}`}
        name={`input_${id}`}
        placeholder={placeholder}
        accept={allowedExtensions}
      />
    </div>
  )
}

export const FormField = ({
  field,
  fields,
  onChange,
  privacy,
  setPrivacy,
  privacyMessage,
}: any) => (
  <>
    {field.type === 'text' && (
      <TextField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'email' && (
      <EmailField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'phone' && (
      <PhoneField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'textarea' && (
      <TextAreaField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'checkbox' && (
      <CheckboxField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'select' && (
      <SelectField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'fileupload' && (
      <FileField
        onChange={onChange}
        value={fields[`input_${field.id}`]}
        field={field}
      />
    )}
    {field.type === 'html' && <HTMLField field={field} />}
    {field.type === 'submit' && (
      <SubmitButton field={field} privacy={privacy} setPrivacy={setPrivacy} privacyMessage={privacyMessage} />
    )}
  </>
)

export default GravityForm
